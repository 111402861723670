<template>
  <v-card>
    <div class="pa-5 mb-5">
      <v-card-title
        primary-title
        class="title"
      >
        Exports comptables
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="currentAnneeScolaire"
            :items="filterAnneesScolaires"
            item-text="libelle"
            label="Année scolaire *"
            return-object
            @change="changeAnneeScolaire"
          />
        </v-col>
      </v-row>
      <v-row v-if="isUserNational">
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="currentCdpe"
            :items="getFilterDepartementsWithNAT"
            :item-text="item => `${item.code} - ${item.nom}`"
            label="Département"
            return-object
            clearable
            :required="false"
            @change="changeCdpe"
          />
        </v-col>
      </v-row>
      <v-row v-if="!isUserLocal">
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="currentConseilLocal"
            :items="getFilterConseilsLocaux"
            :item-text="item => `${item.code} - ${item.nom}`"
            label="Conseil Local"
            return-object
            clearable
            @change="changeConseilLocal"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="getExportsComptables"
        class="elevation-3"
        :loading="loading"
        loading-text="Chargement en cours"
        no-data-text="Aucun export trouvé"
        :sort-by="['filename']"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageText: 'Fichiers par page:',
          itemsPerPageOptions: [20, 50, 100, 200],
        }"
      >
        <template v-slot:item.filename="{ item }">
          <v-btn
            text
            small
            target="_blank"
            @click="download(item)"
          >
            <v-icon
              small
              left
            >
              mdi-download
            </v-icon>
            {{ item.filename }}
          </v-btn>
        </template>
      </v-data-table>
      <Downloader
        :downloading-file="downloadingFile"
        :label="downloadingFileLabel"
      />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Downloader from '../partials/Downloader';

export default {
  name: 'ExportsComptables',
  components: {
    Downloader,
  },
  data() {
    return {
      currentAnneeScolaire: null,
      currentCdpe: null,
      currentConseilLocal: null,
      headers: [
        {
          text: 'Fichier',
          sortable: true,
          value: 'filename',
        },
      ],
      downloadingFile: null,
      downloadingFileLabel: '',
    };
  },
  computed: {
    ...mapState('importsExports/exportsComptables', {
      exportsComptables: state => state.exportsComptables,
      loading: state => state.loading,
    }),
    ...mapState('parametrages/anneeScolaire', {
      filterAnneesScolaires: state => state.filterAnneesScolaires,
    }),
    ...mapGetters('cdpe', [
      'getFilterDepartements',
    ]),
    ...mapGetters('conseilLocal', [
      'getFilterConseilsLocaux',
    ]),
    ...mapGetters('parametrages/anneeScolaire', [
      'getAnneeScolaireCourante',
    ]),
    ...mapGetters('session', [
      'isUserNational',
      'isUserDepartemental',
      'isUserLocal',
      'getPerimetreUser',
    ]),
    getFilterDepartementsWithNAT() {
      return [
        {
          id: 1,
          code: 'NAT',
          nom: 'National',
        },
      ].concat(this.getFilterDepartements);
    },
    getExportsComptables() {
      return this.exportsComptables.map(file => {
        return {
          file: file,
          filename: file.split('/').pop(),
        };
      });
    },
  },
  watch: {
    getAnneeScolaireCourante: {
      immediate: true,
      handler(val) {
        if (val) {
          this.currentAnneeScolaire = val;
          this.initCurrentCdpe();
        }
      },
    },
  },
  methods: {
    ...mapActions('importsExports/exportsComptables', [
      'loadExportsComptablesNationaux',
      'loadExportsComptablesDepartementaux',
      'loadExportsComptablesLocaux',
    ]),
    ...mapActions('conseilLocal', {
      loadFilterConseilsLocaux: 'loadFilterConseilsLocaux',
      resetFilterConseilsLocaux: 'resetState',
    }),
    changeAnneeScolaire() {
      this.initCurrentCdpe();
    },
    initCurrentCdpe() {
      if (this.isUserDepartemental) {
        this.currentCdpe = {
          id: this.getPerimetreUser.perimetre_id,
          code: this.getPerimetreUser.code,
          nom: this.getPerimetreUser.nom,
        };
      } else {
        this.currentCdpe = null;
      }
      this.changeCdpe(this.currentCdpe);
    },
    changeCdpe(val) {
      this.initCurrentConseilLocal();
      if (!val) {
        return;
      }
      if (!this.currentAnneeScolaire) {
        return;
      }
      if (this.currentCdpe.code === 'NAT') {
        this.loadExportsComptablesNationaux({ anneeScolaire: this.currentAnneeScolaire.libelle });
      } else {
        this.loadExportsComptablesDepartementaux({
          anneeScolaire: this.currentAnneeScolaire.libelle,
          cdpeCode: this.currentCdpe.code,
        });
        this.loadFilterConseilsLocaux(this.currentCdpe.id);
      }
    },
    initCurrentConseilLocal() {
      if (this.isUserLocal) {
        this.currentConseilLocal = {
          id: this.getPerimetreUser.perimetre_id,
          code: this.getPerimetreUser.code,
          nom: this.getPerimetreUser.nom,
        };
        this.currentCdpe = {
          id: this.getPerimetreUser.perimetre_appartenance_id,
          code: this.getPerimetreUser.perimetre_appartenance_code,
          nom: this.getPerimetreUser.perimetre_appartenance_nom,
        };
        this.changeConseilLocal(this.currentConseilLocal);
      } else {
        this.currentConseilLocal = null;
        this.resetFilterConseilsLocaux();
      }
    },
    changeConseilLocal(val) {
      if (!val) {
        return;
      }
      this.loadExportsComptablesLocaux({
        anneeScolaire: this.currentAnneeScolaire.libelle,
        cdpeCode: this.currentCdpe.code,
        conseilLocalCode: this.currentConseilLocal.code,
      });
    },
    download(item) {
      this.downloadingFile = {
        url: `/api/resources/backup_compta${item.file}`,
        params: [],
        method: 'GET',
      };
      this.downloadingFileLabel = item.filename;
    },
  },
};
</script>