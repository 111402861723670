<template>
  <ExportsComptables />
</template>

<script>
import ExportsComptables from '../../components/importsExports/ExportsComptables';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ExportsComptables,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('session', [
      'isUserNational',
    ]),
  },
  created() {
    this.loadFilterAnneesScolaires();
    if (this.isUserNational) {
      this.loadFilterDepartements();
    }
  },
  methods: {
    ...mapActions('parametrages/anneeScolaire', [
      'loadFilterAnneesScolaires',
    ]),
    ...mapActions('cdpe', [
      'loadFilterDepartements',
    ]),
  },
};
</script>